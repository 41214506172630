import React from 'react';
import HrefList from '../../components/HrefList';
import LayoutTextPage from '../../layout/layoutTextPage';
import { faq } from '../../lib/faq';
import { mq, theme } from '../../theme';

const faqItems = faq.map(item =>{ 
    return {
        text: item.question,
        href: '/faq/'+item.slug
    }
})

class FAQFullPage extends React.PureComponent<{}, {}> { 
    public render() {
        if(!faqItems.length) {
            return <LayoutTextPage title='Geen vragen bekend'>
                    <div
                    css={mq({
                        maxWidth:'90vw',
                        margin:'auto',
                        })}
                    >
                        Er zijn nog geen geregistreerde vragen.
                    </div>
            </LayoutTextPage>
        }

        return (
            <LayoutTextPage title='Veel gestelde vragen'>
                <div
                    css={mq({
                    maxWidth:'90vw',
                    margin:'auto',
                })}
                >
                    <HrefList items={faqItems} />
                </div>   
        </LayoutTextPage>
        )}
}

export default FAQFullPage;
